import React from "react";
import PropTypes from 'prop-types';
import FiltersIcon from "../../../images/Icons/FiltersIcon/FiltersIcon";
import './style.scss'; 

function FiltersGuideButton ({ selectedFiltersCount, showFilters }) {
    return (
      <div onClick={showFilters} className="filters-guide-btn">
        <span className="filters-count">{selectedFiltersCount}</span>
          <FiltersIcon/>
      </div>
    )
}

FiltersGuideButton.propTypes = {
  selectedFiltersCount: PropTypes.number.isRequired,
  showFilters: PropTypes.func.isRequired
};

export default FiltersGuideButton;