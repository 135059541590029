import React, { useState, useEffect } from "react";
import Slider from '@mui/material/Slider';
import { useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types'
import BaseButton from '../../Buttons/BaseButton';
import store from '../../../../store/store';
import { setPriceRangeFilterAction } from '../../../../store/actions';
import './style.scss'; 

function PriceRanceFilter ({ defaultMinPriceRange, defaultMaxPriceRange, setFilters, setPage }) {
    let [searchParams, setSearchParams] = useSearchParams();
    const defaultSelectedMinPrice = searchParams.get('minPriceRange');
    const defaultSelectedMaxPrice = searchParams.get('maxPriceRange');

    const [priceRangeFilterValue, setPriceRangeFilterValue] = useState([ defaultMinPriceRange, defaultMaxPriceRange ]);

    useEffect(() => {
        if(defaultSelectedMinPrice && defaultSelectedMaxPrice) {
            setPriceRangeFilterValue([defaultSelectedMinPrice, defaultSelectedMaxPrice]);
        } else {
            setPriceRangeFilterValue([defaultMinPriceRange, defaultMaxPriceRange]);
        }
    }, [defaultMinPriceRange, defaultMaxPriceRange, defaultSelectedMinPrice, defaultSelectedMaxPrice]);

    const onUpdate = () => {
        setPage(1);
        setFilters((prevFilters) => ({
            ...prevFilters,
            selectedMinPriceRange: priceRangeFilterValue[0],
            selectedMaxPriceRange: priceRangeFilterValue[1],
            selectedSizes: [],
            selectedBrands:[],
            selectedShoeType: ''
        }));

        store.dispatch(setPriceRangeFilterAction(priceRangeFilterValue));
    }

    const handleChange = (event, newValue) => {
        setPriceRangeFilterValue(newValue);
    };

    return (
        <div className="product-filter price-range-filter">
            <h4>ЦЕНОВИ ДИАПАЗОН</h4>
            <div className="price-range">
                <span>{priceRangeFilterValue[0]}.00 лв.</span>
                <span className="price-range-filter-dash">-</span>
                <span>{priceRangeFilterValue[1]}.00 лв.</span>
            </div>
            <Slider
                getAriaLabel={() => 'Temperature range'}
                value={priceRangeFilterValue}
                onChange={handleChange}
                valueLabelDisplay="auto"
                min={defaultMinPriceRange}
                max={defaultMaxPriceRange}
            />
            <BaseButton onClick={onUpdate} text="приложи цена"/>
        </div>
    )
}

PriceRanceFilter.propTypes = {
    defaultMinPriceRange: PropTypes.number.isRequired,
    defaultMaxPriceRange: PropTypes.number.isRequired,
    setFilters: PropTypes.func.isRequired
};

export default PriceRanceFilter;