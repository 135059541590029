import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import store from '../store/store';
import ProductNotFound from '../components/common/ProductNotFound';
import ProductItem from '../components/common/ProductItem';
import PaginationProducts from '../components/common/PaginationProducts';
import { getProducts } from '../api';
import ProductFilters from "../components/common/ProductFilters";
import { openPreloaderAction, closePreloaderAction } from '../store/actions';
import { isPreloaderLoadingSelector } from '../store/selectors';

export default function ProductsLayout ({ productType }) {
    const isPreloaderLoading = useSelector(isPreloaderLoadingSelector);
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    let [searchParams, setSearchParams] = useSearchParams();
    
    const [filters, setFilters] = useState({
        selectedMinPriceRange: 0,
        selectedMaxPriceRange: 0,
        selectedSizes: [],
        selectedShoeType: '',
        selectedBrands: [],
        selectedPage: 1,
    });

    function onLoad() {
        const selectedFilters = {
            selectedShoeType: searchParams.get('shoeType'),
            selectedSizes: searchParams.getAll('size').map(size => Number(size)),
            selectedBrands: searchParams.getAll('brand'),
            selectedMinPriceRange: searchParams.get('minPriceRange'),
            selectedMaxPriceRange: searchParams.get('maxPriceRange'),
            selectedPage: Number(searchParams.get('page')) || 1,
        };

        setPage(Number(searchParams.get('page')) || 1);
        window.scrollTo(0, 0);
        setFilters(selectedFilters);
    };

    useEffect(() => {
        onLoad();
    },[]);


    useEffect(() => {
        onLoad();
    },[productType]);

    const getProductsApi = (page, limit, params = {}, scrollToTop = false) => {
        store.dispatch(openPreloaderAction());
        
        params['productType'] = productType;
        
        getProducts(page, limit, params)
            .then((data) => {
                const { total_pages, items } = data.data;
                
                setTotalPages(total_pages);
                setProducts(items);
            })
            .catch((err) => console.error('err :', err))
            .finally(() => {
                if(scrollToTop) {
                    window.scrollTo(0, 0)
                }

                store.dispatch(closePreloaderAction())
            });
    }

    return (
        <Container className="mt-4 mb-5">
            <Row>
                <Col xs="12" md="3">
                    <ProductFilters
                        productType={productType}
                        getProductsApi={getProductsApi}
                        filters={filters}
                        setFilters={setFilters}
                        setPage={setPage}
                    />
                </Col>
                <Col xs="12" md="9">
                    <Row className="mb-5">
                        {isPreloaderLoading
                            ? <></>
                            : products.length
                                ? products.map((product) =>
                                    <Col className="mb-4" key={product.article_number} xs="6" xl="4" lg="4" sm="6">
                                        <ProductItem product={product}/>
                                    </Col>)
                                : <ProductNotFound />
                        }
                    </Row>

                    {(products.length && totalPages > 1)
                        ? <Row className="mb-4">
                            <Col>
                                <PaginationProducts 
                                    setPage={setPage}
                                    page={page}
                                    defaultSearchParamsPage={filters.selectedPage} 
                                    getProductsApi={getProductsApi}
                                    setProducts={setProducts}
                                    totalPages={totalPages}
                                />
                            </Col>
                         </Row>
                        : <></>
                    }
                </Col>
            </Row>
        </Container>
    )
}
