import React, { useEffect, useState } from "react";
import ProductItem from "../../common/ProductItem/ProductItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './style.scss'; 
import Slider from "react-slick";
import { Container } from 'reactstrap';
import { getProducts } from '../../../api';

export default function LastAddedProducts() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getProducts(1, 12)
            .then((data) => {
                setProducts(data.data.items);
            })
            .catch((err) => console.error('err :', err))
    }, []); 

    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        nextArrow: <div><FontAwesomeIcon icon={faChevronRight} /></div>,
        prevArrow: <div><FontAwesomeIcon icon={faChevronLeft}/></div>,
        dots: true,
        arrows: true,
        centerPadding: '20px',
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false
              }
            },
          ]
    };
      
  return (
    <Container className="last-added-products">
        <h3 className="mb-4">Последно добавени продукти</h3>
        <Slider {...settings}>
            {products && products.map((product) =>
                <div key={product.article_number} xs="6" xl="3" lg="4" sm="6" className="mb-4 slide-col">
                    <ProductItem product={product}/>
                </div>)
            }
        </Slider>
    </Container>
  );
}