import React from "react";
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import BaseButton from "../../Buttons/BaseButton";
import './style.scss'; 

function ShowHideFiltersButton ({ showHideFilters, isFiltersShown, selectedFiltersCount }) {
    return (
      <Col xs="12" className="text-center show-hide-btn">
            <BaseButton
              className="mb-4 filters-btn"
              text={isFiltersShown ? `скрий филтри (${selectedFiltersCount})` : `покажи филтри (${selectedFiltersCount})`}
              onClick={showHideFilters}
            />
      </Col>
    )
}

ShowHideFiltersButton.propTypes = {
  showHideFilters: PropTypes.func.isRequired,
  isFiltersShown: PropTypes.bool.isRequired,
  selectedFiltersCount: PropTypes.number.isRequired
};

export default ShowHideFiltersButton;